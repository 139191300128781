










import { Component, Mixins } from 'vue-property-decorator'
import ButtonNext from '@/components/atoms/quiz/ButtonNext.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'

@Component({
  components: {
    ButtonNext,
  },
})
export default class Error extends Mixins(BaseQuiz) {
  private get errorMgs() {
    const mgs: string = (this.$route.query.errorMgs ||
      'ただいま接続できません。</n>しばらくしてから再度お試しください。') as string
    return mgs.split('</n>')
  }
}
